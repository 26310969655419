// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("C:\\Users\\admin\\Documents\\Projects\\bikeit-landing-2k19\\src\\pages\\404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-confirm-purchase-tsx": () => import("C:\\Users\\admin\\Documents\\Projects\\bikeit-landing-2k19\\src\\pages\\confirm-purchase.tsx" /* webpackChunkName: "component---src-pages-confirm-purchase-tsx" */),
  "component---src-pages-index-tsx": () => import("C:\\Users\\admin\\Documents\\Projects\\bikeit-landing-2k19\\src\\pages\\index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-payment-tsx": () => import("C:\\Users\\admin\\Documents\\Projects\\bikeit-landing-2k19\\src\\pages\\payment.tsx" /* webpackChunkName: "component---src-pages-payment-tsx" */),
  "component---src-pages-promo-tsx": () => import("C:\\Users\\admin\\Documents\\Projects\\bikeit-landing-2k19\\src\\pages\\promo.tsx" /* webpackChunkName: "component---src-pages-promo-tsx" */),
  "component---src-pages-purchase-tsx": () => import("C:\\Users\\admin\\Documents\\Projects\\bikeit-landing-2k19\\src\\pages\\purchase.tsx" /* webpackChunkName: "component---src-pages-purchase-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "C:\\Users\\admin\\Documents\\Projects\\bikeit-landing-2k19\\.cache\\data.json")

